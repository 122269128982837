import clsx from 'clsx';

import { PMPKeyVisualViewProps } from 'lib/themes/themes';
import { getBackgroundBannerImageUrl } from 'lib/util/imageUrl';
import { useScreenWidth } from 'hooks/useScreenWidth';

import styles from './PMPKeyVisualView.module.css';

const PMPKeyVisualView = ({
  bannerImageUrl,
  bannerTitle,
  bannerDescription,
}: PMPKeyVisualViewProps) => {
  const screenWidth = useScreenWidth();

  const resizedBannerImageUrl = getBackgroundBannerImageUrl(bannerImageUrl, screenWidth);

  return (
    <>
      {' '}
      <div
        className={styles['parallax-bg']}
        style={{ backgroundImage: `url(${resizedBannerImageUrl})` }}
      >
        <div className={clsx('base-top', styles['top'])}>
          <div className="base-top__body">
            <div
              className={clsx(
                'base-top__body__headline',
                styles['pacific-rotate'],
                styles['big-text'],
                styles['txt-center']
              )}
            >
              {bannerTitle}
            </div>
            {/* <p className="base-top__body__since pacific-rotate txt-center">Since 2000</p> */}
          </div>
        </div>
      </div>
      <div className={styles['c-title']}>
        <p className={styles['center-text-box']}>{bannerDescription}</p>
      </div>
    </>
  );
};

export default PMPKeyVisualView;
